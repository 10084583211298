import { isValidPhoneNumber } from 'react-phone-number-input'
import { validateEmails } from '@sevenrooms/core/utils'
import { stripeCard } from './stripeCard'

export const validatePhoneNumber = (val, countryCode) => val && isValidPhoneNumber(val, countryCode)

export const validatePhoneNumberOptional = (val, countryCode) => !val || isValidPhoneNumber(val, countryCode)

export const validateNotEmpty = val => !!val

export const validateDefault = () => true

// Source http://www.regular-expressions.info/email.html
export const validateEmail = email => email != null && email !== '' && validateEmails(email)

export const validateEmailOrShortcode = email => {
  if (email === '<sender_email>') {
    return true
  }
  return validateEmail(email)
}

// Takes comma separated emails. Implicitly required.
// Testing for error requires strict === checks because
// it will return the first erroneaous email.
export const validateMultiEmail = string => {
  if (!validateNotEmpty(string)) {
    return false
  }
  return validateEmails(string)
}

export const validateEmailOptional = validateEmails

export const { validateCardNumber } = stripeCard

export const validateCardMonthExp = val => stripeCard.validateExpiry(val, '2050')

export const validateCardYearExp = val => stripeCard.validateExpiry(12, val)

export const validateBirthday = birthday =>
  /((0[1-9]|1[0-2])\/([01][1-9]|10|2[0-8]))|((0[13-9]|1[0-2])\/(29|30))|((0[13578]|1[0-2])\/31)|02\/29/.test(birthday)

export const validateBirthdayIntl = birthday =>
  /(([01][1-9]|10|2[0-8])\/(0[1-9]|1[0-2]))|((29|30)\/(0[13-9]|1[0-2]))|(31\/(0[13578]|1[0-2]))|29\/02/.test(birthday)

export const validateBirthdayDayMonthFormat = (birthday, localeDateFormat) => {
  if (localeDateFormat === 'mm/dd') {
    return validateBirthday(birthday)
  }

  return validateBirthdayIntl(birthday)
}

export const validateCardCvv = stripeCard.validateCVC

export const validateCurrency = val => /^\d+(?:\.\d{0,2})$|\d+/.test(val)

export const validatePositiveAmount = val => /(\d+(\.\d+)?)/.test(val) && parseFloat(val) > 0

export const validatePositiveOrZeroAmount = val => /(^[+]?[0-9]{1,9}(?:\.[0-9]{1,2})?$)/.test(val) && parseFloat(val) >= 0

// startOfDayHour is specified in the venue settings
export const validateTimeRange = (startTime, endTime, startOfDayTime) => {
  let startDate = new Date(`1/2/1999 ${startTime}`)
  let endDate = new Date(`1/2/1999 ${endTime}`)
  const startOfDayHour = new Date(`1/2/1999 ${startOfDayTime}`).getHours()

  if (endDate.getHours() < startOfDayHour) {
    endDate = new Date(`1/3/1999 ${endTime}`)
  }

  if (startDate.getHours() < startOfDayHour) {
    startDate = new Date(`1/3/1999 ${startTime}`)
  }

  return endDate > startDate
}
