import { combineReducers, configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { Provider, type TypedUseSelectorHook, useSelector } from 'react-redux'
import {
  adminAutoTagTableSliceReducer,
  adminAutoTagTableSliceName,
} from '@sevenrooms/admin-autotags/components/AdminAutoTags/adminAutoTagTableSlice'
import {
  autoTagSlideOutSliceReducer,
  autoTagSlideOutSliceName,
} from '@sevenrooms/admin-autotags/components/AdminAutoTags/AutoTagSlideOut/autoTagSlideOutSlice'
import { customDomainApi } from '@sevenrooms/admin-custom-domains/hooks'
import {
  adminOngoingEmailCampaignTemplatesConfirmDeleteModalReducer,
  adminOngoingEmailCampaignTemplatesConfirmDeleteModalName,
} from '@sevenrooms/admin-ongoing-email-campaign-templates/components/AdminOngoingEmailCampaignTemplates/AdminOngoingEmailCampaignTemplatesConfirmDeleteModal/adminOngoingEmailCampaignTemplatesConfirmDeleteModalSlice'
import {
  adminEmailCampaignTemplatesTableSliceName,
  adminEmailCampaignTemplatesTableSliceReducer,
} from '@sevenrooms/admin-ongoing-email-campaign-templates/components/AdminOngoingEmailCampaignTemplates/adminOngoingEmailCampaignTemplatesTableSlice'
import { adminOngoingEmailCampaignTemplatesToggleStatusModalReducer } from '@sevenrooms/admin-ongoing-email-campaign-templates/components/AdminOngoingEmailCampaignTemplates/AdminOngoingEmailCampaignTemplatesToggleStatusModal/adminOngoingEmailCampaignTemplatesToggleStatusModalSlice'
import {
  adminAutoTagsApi,
  adminClientTagsApi,
  adminEmailCampaignTemplateApi,
  adminSyncBlobsApi,
  adminVenueGroupMergeApi,
  adminVenueApi,
  adminVenueHistoryTrackingApi,
  adminVenueGroupHistoryTrackingApi,
  adminVenuePackageProvisioningApi,
  adminApiAudienceApi,
  adminApiIntegrationTypesApi,
  paymentsDebuggerApi,
} from '@sevenrooms/core/api'
import { LocaleRoot } from '@sevenrooms/core/locales'
import { Router } from '@sevenrooms/core/navigation'
import { Root } from '@sevenrooms/core/ui-kit/layout'

export interface AdminPageProps {}

// Add reducers and middleware from RTK APIs and Slices here
const rootReducer = combineReducers({
  [adminAutoTagsApi.reducerPath]: adminAutoTagsApi.reducer,
  [adminAutoTagTableSliceName]: adminAutoTagTableSliceReducer,
  [adminSyncBlobsApi.reducerPath]: adminSyncBlobsApi.reducer,
  [autoTagSlideOutSliceName]: autoTagSlideOutSliceReducer,
  [adminEmailCampaignTemplateApi.reducerPath]: adminEmailCampaignTemplateApi.reducer,
  [adminClientTagsApi.reducerPath]: adminClientTagsApi.reducer,
  [customDomainApi.reducerPath]: customDomainApi.reducer,
  [paymentsDebuggerApi.reducerPath]: paymentsDebuggerApi.reducer,
  [adminApiAudienceApi.reducerPath]: adminApiAudienceApi.reducer,
  [adminApiIntegrationTypesApi.reducerPath]: adminApiIntegrationTypesApi.reducer,
  [adminVenueGroupMergeApi.reducerPath]: adminVenueGroupMergeApi.reducer,
  [adminVenueApi.reducerPath]: adminVenueApi.reducer,
  [adminVenueHistoryTrackingApi.reducerPath]: adminVenueHistoryTrackingApi.reducer,
  [adminVenueGroupHistoryTrackingApi.reducerPath]: adminVenueGroupHistoryTrackingApi.reducer,
  [adminVenuePackageProvisioningApi.reducerPath]: adminVenuePackageProvisioningApi.reducer,
  [adminEmailCampaignTemplatesTableSliceName]: adminEmailCampaignTemplatesTableSliceReducer,
  [adminOngoingEmailCampaignTemplatesConfirmDeleteModalName]: adminOngoingEmailCampaignTemplatesConfirmDeleteModalReducer,

  toggleAdminEmailCampaignTemplateStatusModal: adminOngoingEmailCampaignTemplatesToggleStatusModalReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      adminAutoTagsApi.middleware,
      adminEmailCampaignTemplateApi.middleware,
      adminSyncBlobsApi.middleware,
      adminVenueApi.middleware,
      adminVenuePackageProvisioningApi.middleware,
      adminApiAudienceApi.middleware,
      adminApiIntegrationTypesApi.middleware,
    ]),
})

export const useStoreSelector: TypedUseSelectorHook<RootState> = useSelector

export function AdminPage({ children }: React.PropsWithChildren<AdminPageProps>) {
  return (
    <Provider store={store}>
      <LocaleRoot>
        <Root theme="vx">
          <Router>{children}</Router>
        </Root>
      </LocaleRoot>
    </Provider>
  )
}
